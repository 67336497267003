<template>
    <div class="container container-blue">
        <Background/>
        <div class="scroll-wrapper">
            <!-- topic -->
            <h3 class="header-white header-marin-top">สร้างบัญชี</h3>

            <img src="@/assets/wfh.png" style="width: 250px; z-index: 1; align-self: center;"/>
            <a-form 
                layout="vertical"
                :hideRequiredMark="true"
                :model="user_form"
                @finish="submit_register"
            >
                <div class="input-wrapper">
                    <a-row class="input-width" type="flex" style="gap:1rem">
                        <div style="flex: 2;">
                            ชื่อ
                            <a-form-item name="first_name" :rules="[{ required: true, message: 'กรุณากรอกชื่อ'}]">
                                <a-input class="input-border" v-model:value="user_form.first_name">
                                </a-input>
                            </a-form-item>
                        </div>
                        <div style="flex: 3;">
                            นามสกุล
                            <a-form-item name="last_name" :rules="[{ required: true, message: 'กรุณากรอกนามสกุล'}]">
                                <a-input class="input-border" v-model:value="user_form.last_name">
                                </a-input>
                            </a-form-item>
                        </div>
                    </a-row>
                    <div class="input-width">
                                ชื่อบริษัทหรือองค์กร (ไม่ต้องกรอกคำว่าบริษัท)
                             <a-form-item name="company" >
                               <div class="input-border">
                                    <a-auto-complete
                                        style="
                                            top: 50%;
                                            left: 50%;
                                            transform: translate(-50%, -50%);
                                        "
                                        v-model:value="company.label"
                                        :options="companies.map(({ id, name }) => ({ value: id, label: `${name}` }))"
                                        :filter-option="false"
                                        :bordered="false"
                                        @search="get_companies"
                                        @select="on_select_company"
                                        @change="on_change_company"
                                    ></a-auto-complete>
                               </div>
                               <template v-if="fetching" #notFoundContent>
                                        <a-spin size="small" />
                                </template>
                                <!-- show company error -->
                                <template v-if="company_error">
                                    <div style="color: rgb(221, 52, 52);">{{company_error}}</div>
                                </template>
                            </a-form-item>
                    </div>
        
                    <div class="input-width">
                        เบอร์โทรศัพท์
                        <a-form-item name="phone" :rules="[{ required: true, message: 'กรุณากรอกเบอร์โทรศัพท์'}]">
                            <a-input class="input-border" type="phone" v-model:value="user_form.phone">
                            </a-input>
                        </a-form-item>
                    </div>
                    <div class="input-width">
                        อีเมล
                        <a-form-item 
                             name="email" 
                             :rules="[{ required: true, message: 'กรุณากรอกอีเมล'}]"
                        >
                            <a-input class="input-border" type="email" v-model:value="user_form.email">
                            </a-input>
                        </a-form-item>
                    </div>
                    <a-button 
                        html-type="submit"
                        type="primary" 
                        shape="round" 
                        size="large" 
                        block
                        style="
                        border: none;
                        margin-top: 1rem;
                        margin-bottom: 1.2rem; 
                        height: 50px;
                        font-size: 20px;
                        background-color: var(--positive-button-color);">
                            ยืนยัน
                    </a-button>
                </div>
            </a-form>
        </div>
    </div>
</template>

<script>
import Background from '@/components/Background.vue'
import Api from '../../api/Api'
export default {
    name: 'Register',
    components: {
        Background
    },
    data() {
        return {
            companies: [],
            fetching: false,
            user_form: {
                company: {},
                fullname: null,
                phone: null,
                email: null,
            },
            company: {},
            company_error: null,
        }
    },
    methods: {
        async get_companies(v){
            if (v != '') {
                this.fetching = true
                const res = await Api.get_company({name: v})
                this.fetching = false
                this.companies = res
            }
        },
        on_select_company(v, option){
            this.company = option
        },
        on_change_company(v){
            this.company = {}
            this.company.label = v
        },
        async submit_register(){
            if(!this.company.label){
                this.company_error = 'กรุณาระบุบริษัท'
                return
            }
            this.user_form.fullname = `${this.user_form.first_name} ${this.user_form.last_name}`
            this.user_form.company.id = this.company.value
            this.user_form.company.name = this.company.label
            this.$emitter.emit('user_form', this.user_form)
            this.$router.push({ name: 'Consent' })
        }
    },
    mounted() {
        // this.get_companies(' ')
    }
}
</script>

<style lang="css">
.input-border {
    width: 100%;
    height: 50px;
    border: 2px solid var(--border-input-color) !important;
    border-radius: 25px !important;
    background-color: white;
}
.input-width {
    width: 100%;
    color: white;
}
.input:focus {
    outline: none;
}
.input-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;
    z-index: 1;
}
</style>