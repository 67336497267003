<template>
    <img v-if="color==='white'" src="@/assets/white-logo.png" class="bg-wrapper"/>
    <img v-else-if="color==='blue'" src="@/assets/blue-logo.png" class="bg-wrapper"/>
</template>

<script>
export default {
    name: 'Background',
    props: {
        color: {
            type: String,
            default: 'white'
        }
    }
}
</script>

<style lang="css">
.bg-wrapper {
    position: absolute;
    z-index: 0;
    width: 100%;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0%);
}
</style>