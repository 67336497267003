import { createApp } from 'vue'
import Antd from 'ant-design-vue'
import App from './App.vue'
import router from './router'
import 'ant-design-vue/dist/antd.css'
import mitt from 'mitt'

const emitter = mitt()
const app = createApp(App)
app.config.globalProperties.$emitter = emitter

window.liff.init({ liffId: process.env.VUE_APP_LIFF_ID }, async () => {
  if (!window.liff.isLoggedIn() && !window.liff.isInClient()) {
      window.liff.login({ redirectUri: 'https://liff.employyim.com/' });
  }else{
  app
    .use(router)
    .use(Antd)
    .mount('#app')
  }
}, err => {
  if (window.liff.isInClient()) {
    window.liff.closeWindow();
  } else {
    alert(err);
  }
});

