import httpClient from './httpClient';
const prefix = '/user'
export default {
    async register(body) {
      const { data } = await httpClient({ requiresAuth: true }).post(`${prefix}/register`, body);
      
      return data;
    },

    async profile() {
      const { data } = await httpClient({ requiresAuth: true }).get(`${prefix}/profile`);
      localStorage.setItem('user', JSON.stringify(data));
      
      return data;
    },

    async update_profile(body) {
      const { data } = await httpClient({ requiresAuth: true }).put(`${prefix}/profile`, body);
      
      return data;
    },

    async create_order(body) {
      const { data } = await httpClient({ requiresAuth: true }).post(`${prefix}/order`, body);
      
      return data;
    },

    async get_orders({ page }) {
      const { data } = await httpClient({ requiresAuth: true }).get(`${prefix}/order?page=${page}`);
      
      return data;
    },

    async get_company({ name }) {
      const { data } = await httpClient({ requiresAuth: false }).get(`get_company?name=${name}`);
      
      return data;
    },

    async cal_order(body) {
      const { data } = await httpClient({ requiresAuth: true }).post(`${prefix}/cal-order`, body);
      
      return data;
    }
  }