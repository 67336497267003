<template>
            <img src="@/assets/bg-top.png" style="height: 100px; width: 100%;">
            <div class="top-container">
                <div class="profile-container">
                        <img class="coin" src="@/assets/coin.png" style="width: 70px;">
                    <div class="profile-detail">
                        <div style="color: white; font-size: 20px;">{{user.fullname}}</div>
                        <div style="color: white; font-size: 12px;">
                            <div>{{ user.company.name }}</div>
                        </div>
                    </div>       
                </div>
            </div>
</template>

<script>
export default {
    name: 'ProfileTopper',
    props:{
        user: {
            type: Object,
            default: null
        }
    }
}
</script>

<style lang="css">
    .top-container {
        height: 100%;
        position: absolute; 
        left: 1rem;
        top: 0;
        align-items: center;
    }
    .profile-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 20px;
    }
    .profile-detail {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        color: white;
    }
</style>