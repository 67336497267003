import axios from 'axios';
// Interceptor for responses
const responseInterceptor = (response) => {
    switch(response.status) {
        case 200: 
            // console.log({ path: response.config.url, status: response.status});
            break;
        // any other cases
        default:
          console.log(response);
    }
  
    return response;
  }
  
  // interceptor to catch errors
  const errorInterceptor = error => {
  
    // check if it's a server error
    if (!error.response) {
      return Promise.reject(error);
    }
  
    // all the error responses
    switch(error.response.status) {
        case 400:
            console.error(error.response.status, error.message);
            break; 
        case 401:
            // window.location.href = '/register';
            break;
        default:
            console.error(error.response.status, error.message);
    }
    return Promise.reject(error);
  }


export default ({ requiresAuth }) => {
    var options = {
      baseURL: 'https://api.employyim.com'
      // baseURL: 'http://192.168.1.92:3000'
    }

    if (requiresAuth) {
      options.headers = { 
        access_token: window.liff.getAccessToken()
      }
    }
  
    const instance = axios.create(options);
    instance.interceptors.response.use(responseInterceptor, errorInterceptor);
  
    return instance;
  };