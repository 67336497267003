import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home/Home.vue'
import Consent from '../views/Consent/Consent.vue'
import Register from '../views/Auth/Register.vue'
import WithdrawConfirm from '../views/Home/WithdrawConfirm.vue'
import Contract from '../views/Consent/Contract.vue'
const routes = [
  {
    path: '/register',
    name: 'Register',
    component: Register
  },
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/consent',
    name: 'Consent',
    component: Consent,
    props: true
  },
  {
    path: '/contract',
    name: 'Contract',
    component: Contract,
    props: true
  },
  {
    path: '/withdraw-confirm',
    name: 'WithdrawConfirm',
    component: WithdrawConfirm,
    props: true
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

console.log('v1.0.12')

export default router
