<template>
    <div ref="scrollContainer">
        <Loading v-if="!user" />
        <div v-else class="container">
            <Background color="blue"/>
            <ProfileTopper :user="user"/>
            <Approving v-if="!user.is_active"/>
            <div
                v-if="user.is_active" class="withdraw-container" @scroll="on_scroll"
                >
                    <PullToRefresh
                        ref="pullRefresh"
                        :container="scrollContainer"
                        @refresh="onRefresh"
                    />
                    <WithdrawLimitCard :user="user"/>
                    <div>
                        <a-form
                                style="width: 100%; padding: 0 1rem;"
                                layout="vertical"
                                :hideRequiredMark="true"
                                :model="create_order"
                                @finish="to_withdraw_confirm"
                            >
                                <div style="width: 100%;">
                                    ระบุจำนวนเงินที่ต้องการเบิก (บาท)
                                    <a-form-item name="amount" 
                                        :rules="[
                                            {
                                                validator: amount_validator,
                                            },
                                        ]"
                                    >
                                        <a-input class="input-border" type="number" v-model:value="create_order.amount" :min="1"></a-input>
                                    </a-form-item>
                                </div>
                                <a-button 
                                    html-type="submit" 
                                    class="withdraw_btn" 
                                    type="primary" 
                                    shape="round" 
                                    size="large"  
                                    block
                                    :disabled="!user.allow_order"
                                >
                                    เบิกเงิน
                                </a-button>
                        </a-form>
                        <div class="list-container">
                                <div class="list-header">
                                    <div>รายการทั้งหมด</div>
                                    <div style="color: var(--text-color-light); font-size: 12px;">จำนวน {{ order_paginate.total}} รายการ</div>
                                </div> 
                                <a-list
                                    class="list-card"
                                    :data-source="order_paginate.data"
                                    >
                                        <template #loadMore>
                                        <div
                                            v-if="!order_init_loading && order_more_loading"
                                            :style="{ textAlign: 'center', marginTop: '12px', height: '32px', lineHeight: '32px' }"
                                        >
                                            <a-spin/>
                                        </div>
                                        </template>
                                        <template #renderItem="{ item }">
                                            <WithdrawItem :data="item"/>
                                        </template>
                                </a-list>
                        </div>
                    </div>
                </div>
        </div>
    </div>
</template>

<script>
import ProfileTopper from '@/components/ProfileTopper.vue'
import Approving from './components/Approving.vue'
import WithdrawItem from './components/WithdrawItem.vue'
import Background from '@/components/Background.vue'
import WithdrawLimitCard from './components/WithdrawLimitCard.vue'
import Loading from '@/components/Loading.vue'
import PullToRefresh from '../../components/PullToRefresh.vue'

import Api from '../../api/Api'
export default {
    name: 'Home',
    components: {
        ProfileTopper,
        Approving,
        WithdrawItem,
        Background,
        WithdrawLimitCard,
        Loading,
        PullToRefresh
    },
    data(){
        return  {
            scrollContainer: null,
            user: null,
            create_order: {
                amount: null
            },
            order_paginate: {
                page: 1,
                data: [],
                lastPage: 1,
                total: 0
            },
            pull_refresh: false,
            order_init_loading: true,
            order_more_loading: false
        }
    },
    methods: {
        to_withdraw_confirm(){
            this.$emitter.emit('withdraw_amount', parseFloat(this.create_order.amount))
            this.$router.push({name: 'WithdrawConfirm'})
        },
        async get_profile(){
            this.user = await Api.profile()
            if(this.user){
                this.user = JSON.parse(localStorage.getItem('user'))
                this.get_orders()
            }
        },
        amount_validator(rule, value){
            if(value > this.user.balance){
                return Promise.reject('จำนวนเงินที่คุณขอเกินวงเงินที่สามารถเบิกได้')
            }else if(value < 1){
                return Promise.reject('กรุณากรอกจำนวนเงิน')
            }else{
                return Promise.resolve()
            }
        },
        async get_orders(){
            this.order_init_loading = true
            var res = await Api.get_orders({page: 1})
            this.order_init_loading = false
            this.order_paginate = res
        },
        async on_scroll(e){
            if(e.target.scrollTop + e.target.clientHeight >= (e.target.scrollHeight-5)  && !this.order_more_loading){
                if(this.order_paginate.page < this.order_paginate.lastPage){
                    this.order_more_loading = true
                    var res = await Api.get_orders({page: this.order_paginate.page + 1})
                    this.order_more_loading = false
                    this.order_paginate.page = res.page
                    this.order_paginate.data = this.order_paginate.data.concat(res.data)
                }
            }
        },
        async onRefresh() {
            await this.get_profile()
            await this.get_orders()
            this.$refs.pullRefresh.refreshSuccess()
        },
    },
    async created(){
        await this.get_profile()
        this.$refs.pullRefresh.init()
    },
    mounted(){
        this.scrollContainer = this.$refs.scrollContainer
    }
}
</script>

<style lang="css">
.withdraw-container {
    position: relative;
    overflow: auto;
    padding: 0 0.7rem;
    width: 100%;
    height: 100vh;
}
.withdraw-over {
    margin-top: 0.3rem;
    font-size: 0.8rem;
    color: red;
    align-self: center;
}
.list-container {
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    position: relative;
    overflow: auto;
}
.list-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    padding: 0 1rem;
}
.list-card {
    margin: 0.5rem 0 1rem 0; 
    width: 100%;
    background-color: white;
    border-radius: 25px;
    box-shadow: 0px 4px 4px 5px rgba(0,0,0,0.05);
}
.withdraw_btn {
    font-size: 18px !important; 
    min-height: 50px !important; 
    color: white !important;
    background-color: var(--positive-button-color) !important;
    border: none !important;
}
.withdraw_btn:disabled {
    background-color: var(--gray-color) !important;
}
</style>

