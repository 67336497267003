<template>
    <div class="approving">
        <img src="@/assets/people.png">
        <div class="detail">บัญชีของคุณกำลังรออนุมัติ</div>
    </div>
</template>

<script>
export default {
    name: 'Approving',
    props:{
    }
}
</script>

<style lang="css">
.approving {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 1rem;
    height: 100%;    
}
.detail {
    font-size: 1.1rem;
    color: var(--text-color);
}
</style>