<template>
    <div class="limit-card">
        <div>
            <div style="font-size: 24px; color: var(--primary-color); font-weight: bold;">SMILE AS YOU WORK</div>
            <div style="font-size: 16px; color: var(--blue-color); font-weight: bold;">เครดิตเบิกเงินเดือนล่วงหน้า</div>
            <div style="font-size: 12px; font-weight: bold; margin-top: 0.6rem;">วงเงินคงเหลือ</div>
            <div style="font-size: 24px; font-weight: bold;">฿{{ user.balance.toLocaleString() }}</div>
            <!-- <div style="font-size: 12px;">วงเงินรวม ฿{{ (user.salary*user.company.max_payable).toLocaleString() }}</div> -->
            <div style="font-size: 12px; margin-top: 0.5rem;">จำนวนวันที่ทำงาน {{ user.workday }} วัน</div>
            <div style="font-size: 12px;" >ไม่สามารถเบิกเงินได้ระหว่างวันที่ {{ user.company.unpay_day_start }} - {{ user.company.unpay_day_end }}</div>
        </div>
        <img src="@/assets/wallet.png" style="position: absolute; right: 0.8rem; bottom: 1.5rem; " >
    </div>
</template>

<script>
export default {
    name: 'WithdrawLimitCard',
    props: {
        user: {
            type: Object,
            default: null
        }
    }
}
</script>

<style lang="css">
.limit-card {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 1rem 0;
    width: 99%;
    box-shadow: 5px 4px 4px rgba(0, 0, 0, 0.1);
    background-color: white;
    padding: 1rem 1rem;
    border-radius: 20px;
    position: relative;
    background-color: #FBFBFB;
}
</style>