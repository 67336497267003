<template>
    <div class="container">
        <Background color="blue"/>
        <div class="scroll-wrapper">
                 <!-- topic -->
                <h3 class="header-black header-marin-top">หนังสือให้ความยินยอมในการเปิดเผยข้อมูล</h3>
                <!-- content -->
                <div class="content">
                    <img src="@/assets/note.png" style="width: 190px; z-index: 1; align-self: center;"/>
                    <p style="align-self: flex-end;">วันที่ {{render_date()}}</p>
                    <p> ข้าพเจ้า {{ user_form.fullname }}<br>
                        เป็นพนักงานของบริษัท {{ user_form.company?.name }}<br>
                        หมายเลขโทรศัพท์ {{ user_form.phone }}<br>
                        อีเมล {{user_form.email}}<br>
                    </p>
                    <p>โดยหนังสือฉบับนี้ ข้าพเจ้า<br>
                    •   ยินยอมให้ บริษัท เอ็มพลอยยิ้ม จำกัด และ พันธมิตรทางธุรกิจ ของ บริษัท เอ็มพลอยยิ้ม จำกัด ขอข้อมูลส่วนบุคคลของข้าพเจ้า (ชื่อ-นามสกุล รหัสพนักงาน ตำแหน่ง สถานที่ปฏิบัติงาน อัตราเงินเดือน อายุงาน วันเกษียณอายุ อายุงานที่เหลือ สลิปเงินเดือน อีเมล เบอร์โทรศัพท์ เลขบัญชีธนาคารที่รับเงินเดือน ข้อมูลการต้องคดี และข้อมูลอื่นๆ ที่จำเป็นต่อการพิจารณาให้บริการเบิกเงินเดือนล่วงหน้า) จากหน่วยงานหรือองค์กรเอกชนที่ข้าพเจ้าปฏิบัติงานอยู่ซึ่งเป็นผู้ครอบครองข้อมูลส่วนบุคคลของข้าพเจ้า เพื่อการให้บริการเบิกเงินเดือนล่วงหน้า และการดำเนินการใด ๆ ได้ตามวัตถุประสงค์ของการให้บริการ<br>
                    •   ยินยอมให้ผู้ควบคุมหรือครอบครองข้อมูลส่วนบุคคลของข้าพเจ้า เปิดเผย และแลกเปลี่ยนข้อมูลส่วนบุคคลของข้าพเจ้าให้แก่ บริษัท เอ็มพลอยยิ้ม จำกัด และ พันธมิตรทางธุรกิจ ของ บริษัท เอ็มพลอยยิ้ม จำกัด เพื่อการให้บริการเบิกเงินเดือนล่วงหน้า และการดำเนินการใด ๆ ได้ตามวัตถุประสงค์ของการให้บริการ<br>
                    •   ให้ถือว่าคู่ฉบับและบรรดาสำเนาภาพถ่าย ข้อมูลอิเล็กทรอนิกส์ หรือโทรสารที่ทำสำเนาขึ้น จากหนังสือให้ความยินยอมฉบับนี้โดยการถ่ายสำเนา ถ่ายภาพ หรือบันทึกไว้ไม่ว่าในรูปแบบใดๆ เป็นหลักฐานในการให้ความยินยอมของข้าพเจ้าเช่นเดียวกัน<br>
                        ข้าพเจ้าได้เข้าใจข้อความในหนังสือยินยอมฉบับนี้โดยตลอดแล้ว จึงได้กดยินยอมผ่านทางแอปพลิเคชันไว้เป็นหลักฐาน ณ วัน เดือน ปี ที่ระบุข้างต้น
                    </p>

                    <p style="align-self: flex-end; text-align: end;">
                        ลงชื่อ<br>{{ user_form.fullname  }}
                     </p>

                        <!-- agree and not agree button -->
                <div class="bottom">
                    <a-button 
                        class="btn-not-agree" 
                        type="primary" 
                        shape="round" 
                        size="large" 
                        block
                        :onClick="onNotAgree">
                            ไม่ยินยอม
                    </a-button>
                    <a-button 
                        class="btn-agree" 
                        type="primary" 
                        shape="round" 
                        size="large" 
                        block
                        :loading="loading"
                        :onClick="onAgree">
                            ยินยอม
                    </a-button>
                </div>
                </div>
            </div>
    </div>
</template>

<script>
import Background from '@/components/Background.vue'

import Api from '../../api/Api'

import dayjs from 'dayjs'
export default {
    name: 'Consent',
    props: {
        user_form: {
            type: Object,
            default: () => ({})
        }
    },
    components: {
        Background
    },
    data(){
        return {
            loading: false,
        }
    },
    methods: {
        render_date(datetime) {
            return datetime ? dayjs(datetime).format('DD/MM/YYYY') : dayjs().format('DD/MM/YYYY')
        },
        async onAgree() {
            this.loading = true
            const {is_registered} = await Api.register(this.user_form)
            if(is_registered){
                this.$router.replace('/')
            }
            this.loading = false
        },
        onNotAgree() {
            this.$router.back()
        }
    },
}
</script>

<style lang="css">
    .content {
        height: 100%;
        position: relative;
        display: flex;
        flex-direction: column;
        overflow: auto;
    }
    .bottom {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 15px;
        width: 100%;
        margin: auto 0 1rem 0;
        position: relative;
    }
    .btn-not-agree {
        border: 2px solid var(--positive-button-color) !important;
        color: var(--positive-button-color) !important;
        background-color: white !important;
        height: 50px !important;
        font-size: 18px !important;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1) !important;
    }
    .btn-agree {
        background-color: var(--positive-button-color) !important;
        border: none !important;
        color: #FFF !important;
        height: 50px !important;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1) !important;
        font-size: 18px !important;
    }
</style>