<template>
     <div ref="el" class="pull-refresh" :class="{ 'pull-animate': !startY }" @transitionend="handleTransitionEnd">
      <!-- <div v-if="isRefreshing" class="loading"></div>
      <div v-else class="arrow" :style="{ transform: canRelease ? 'rotate(180deg)' : '' }" ></div> -->
      <div class="pull-content">
        <div v-if="isRefreshing">
          <a-spin size="large"/>
        </div>
        <div v-else-if="canRelease">
          <a-spin size="large"/>
        </div>
        <div v-else></div>
      </div>
    </div>
</template>

<script>
export default {
    name: 'pull-refresh',
    props: {
        container: {
            type: Object,
            required: true
        }
    },
    emits: ['refresh'],
    components: {
    },
    data(){
        return  {
            canRelease: false,
            startY: 0,
            isPulling: false,
            isRefreshing: false
        }
    },
    methods: {
        async init() {
          await this.$nextTick()
          this.container.addEventListener('mousedown', this.startPull)
          this.container.addEventListener('touchstart', this.startPull, { passive: false })
          this.container.addEventListener('mousemove', this.pulling)
          this.container.addEventListener('touchmove', this.pulling, { passive: false })
          for (const event of ['mouseup', 'mouseleave', 'touchend', 'touchcancel']) {
              this.container.addEventListener(event, this.endPull)
          }
        },
        startPull(e) {
          if (this.container.scrollTop > 0 || this.isRefreshing) return
          this.startY = (e.clientY ? e.clientY : e.touches[0].clientY) || 0
        },
        pulling(e) {
          if (!this.startY || this.isRefreshing) return
          const y = (e.clientY ? e.clientY : e.touches[0].clientY) || 0
          let height = y - this.startY
          if (!this.container.scrollTop && height < 0) {
              this.startY = 0
              this.container.style.setProperty('overflow', '')
              this.isPulling = false
              return
          }
          if (height < 80) return
          this.container.style.setProperty('overflow', 'hidden', 'important')
          height -= 110
          this.canRelease = height > 120
          this.isPulling = true
          this.$refs.el.style.height = `${this.canRelease && height > 0 ? Math.sqrt(height * 120) : height}px`
        },
        endPull() {
          if (!this.startY || this.isRefreshing) return

          this.$refs.el.style.height = `${this.canRelease ? 120 : 0}px`
          if (this.canRelease) {
              this.$emit('refresh')
              this.isRefreshing = true
          }
          this.container.style.setProperty('overflow', '')
          this.startY = 0
        },
        refreshSuccess() {
          this.$refs.el.style.height = 0
          this.isPulling = false
        },
        handleTransitionEnd() {
          if (!this.isPulling) {
              this.canRelease = false
              this.isRefreshing = false
          }
        },
        stopPull() {
          this.container.style.setProperty('overflow', '')
          this.startY = 0
          this.canRelease = false
          this.isPulling = false
          this.isRefreshing = false
          this.$refs.el.style.height = 0
        }
    },
    mounted() {
    }
}
</script>

<style>

.scroll-container {
    overflow-y: auto;
    width: 100%;
    height: 100%;
    max-width: 480px;
    margin: auto;
    position: relative;
}
.scroll-container::-webkit-scrollbar {
  width: 10px;
}
.scroll-container::-webkit-scrollbar-thumb {
  border: 3px solid transparent;
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.6);
  background-clip: content-box;
}
.scroll-container::-webkit-scrollbar-track {
  background-color: transparent;
}
.pull-refresh {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  overflow: hidden;
  height: 0;
}

.pull-animate {
  transition: height 0.3s;
}

.pull-content {
  line-height: 20px;
  letter-spacing: 0.3px;
  font-size: 14px;
  font-weight: bold;
}

.arrow {
  position: relative;
  top: -3px;
  width: 2px;
  height: 12px;
  border-right: 2px solid #000000;
  box-sizing: content-box;
  transition: transform ease 0.5s;
}
.arrow::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  border: 3px solid #000000;
  border-top-color: transparent;
  border-left-color: transparent;
  transform: rotate(45deg);
  border-radius: 1px;
}

.loading {
  flex-shrink: 0;
  width: 14px;
  height: 14px;
  margin-bottom: 2px;
  border-radius: 50%;
  border: 2px solid #000000;
  border-left-color: transparent;
  -webkit-animation: 0.8s cubic-bezier(0.45, 0.05, 0.55, 0.95) infinite loading;
          animation: 0.8s cubic-bezier(0.45, 0.05, 0.55, 0.95) infinite loading;
}

@-webkit-keyframes loading {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

@keyframes loading {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
</style>