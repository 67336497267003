<template>
    <div class="list-tem">
        <img class="withdraw-icon" src="@/assets/money.png"/>
        <div class="withdraw-info">
                    <div class="row-space-between">
                      <div style="font-size: 12px;">เบิกเงินเดือนล่วงหน้า</div>
                      <div style="font-size: 14px;">฿{{data.amount.toLocaleString()}}</div>
                    </div>
                    <div class="row-space-between">
                      <div style="font-size: 10px; color: var(--text-color-light);">{{format_date(data.created_at)}}</div>
                      <div v-if="data.status_id==2" style="color: var(--primary-color); font-size: 10px;">อนุมัติแล้ว</div>
                      <div v-else-if="data.status_id==3" style="color: red; font-size: 10px;">ไม่อนุมัติ</div>
                      <div v-else style="color: #5096f294; font-size: 10px;">รอการอนุมัติ</div>
                    </div>
        </div>
    </div>
</template>

<script>
import dayjs from 'dayjs'
export default {
    name: 'WithdrawItem',
    props: {
        data: {
            type: Object,
            default: null
        }
    },
    methods: {
        format_date(date){
            return dayjs(date).locale('th').format('DD MMM YYYY')
        }
    }
}
</script>

<style lang="css">

.list-tem {
    width: 95%;
    padding: 1rem;
    border-bottom: 1px solid #E5E5E5;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.withdraw-icon {
    margin-right: 1rem;
}
.withdraw-info {
    display: flex;
    flex-direction: column;
    width: 100%;
}
.row-space-between {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}
</style>