<template>
  <div id="app">
      <Loading v-if="loading"/>
      <router-view v-else
        :user_form="user_form"
        :withdraw_amount="withdraw_amount"
        :order_calculated="order_calculated"
      />
  </div>
</template>

<script>
import Api from './api/Api'

import Loading from './components/Loading.vue'
export default {
  name: 'App',
  components: {
    Loading
  },
  data(){
    return{
      loading: true,
      user_form: {},
      withdraw_amount: 0,
      order_calculated: {}
    }
  },
  methods: {
    async liffInit(){
      this.loading = true
      try {
        localStorage.removeItem('user')
        const res = await Api.profile()
        localStorage.setItem('user', JSON.stringify(res))
        if(res.is_registered){
          this.$router.replace('/')
        }else{
          this.$router.replace('/register')
        }
      } catch (error) {
        this.$router.replace('/register')
      }
      this.loading = false
    },
    reciver(){
      this.$emitter.on('user_form', (user_form) => {
        this.user_form = user_form
      })
      this.$emitter.on('withdraw_amount', (withdraw_amount) => {
        this.withdraw_amount = withdraw_amount
      })
      this.$emitter.on('order_calculated', (order_calculated) => {
        this.order_calculated = order_calculated
      })
    }
  },
  beforeMount(){
    this.liffInit()
  },
  mounted(){
    this.reciver()
  }
}
</script>

<style lang="css">
@import url('https://fonts.googleapis.com/css2?family=Mitr&display=swap');
#app {
  font-family: Mitr;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: start;
  color: #2c3e50;
}

html, body {
  margin: 0;
  padding: 0;
}

:root {
  --primary-color: #5096F2;
  --secondary-color: #E7EDF7;
  --text-color: #555555;
  --text-color-light: #A0A0A0;
  --positive-button-color: #19C27D;
  --blue-color: #2D62A6;
  --border-input-color: #ADA7A7;
  --gray-color: #E0E0E0;
}

.container {
    width: 100vw;
    max-width: 100vw;
    height: 100vh;
    margin: auto;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    align-items: center;
    position: relative;
    overflow: hidden;
}


.container-blue{
    background-color: var(--blue-color);
}

.wrapper {
  padding: 0 1rem;
}

.scroll-wrapper {
  width: 100%;
  height: 100%;
  overflow: auto;
  padding: 0 1rem;
  position: relative;
  display: flex;
  flex-direction: column;
}
.header-black {
  font-size: 1.4rem;
  font-weight: bold;
  color: black;
  text-align: center;
}
.header-white {
  font-size: 1.4rem;
  font-weight: bold;
  color: white;
  text-align: center;
}
.sub-header-black {
  font-size: 1.1rem;
  font-weight: bold;
  color: black;
}
p {
  font-size: 14px;
  color: var(--text-color);
}

.header-marin-top {
  margin-top: 2rem;
}
</style>
