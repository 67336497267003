<template>
    <div class="container">
        <Background color="blue"/>
        <div class="scroll-wrapper">
                <!-- topic -->
                <div class="header-black header-marin-top">ใบคำขอเบิกเงินเดือนล่วงหน้า</div>
                <!-- content -->
                <div class="content">
                        <p style="align-self: flex-end;">วันที่ {{ render_date() }}</p>

                        <p> ข้าพเจ้า {{ user.fullname }}<br>
                            เป็นพนักงานของ บริษัท {{ user.company.name}}<br>
                            หมายเลขโทรศัพท์ {{ user.phone }}<br>
                            อีเมล {{ user.email }}<br>
                        </p>
                        <p>ข้าพเจ้ามีความประสงค์ขอเบิกเงินค่าจ้างล่วงหน้าจาก บริษัท เอ็มพลอยยิ้ม จำกัด ซึ่งเป็นผู้ให้บริการเบิกเงินค่าจ้างล่วงหน้าให้แก่บริษัทที่ข้าพเจ้าปฏิบัติงานอยู่ 
                           เป็นจำนวนเงิน {{ parseFloat(`${order_calculated.amount}`).toLocaleString() }} บาท
                        </p>
                        <p>
                            ทั้งนี้ข้าพเจ้าตกลงรับทราบและยอมรับเงื่อนไขที่บริษัทต้องหักค่าจ้างของข้าพเจ้า ตามจำนวนที่ข้าพเจ้าได้มีการขอเบิกเงินค่าจ้างล่วงหน้าในแต่ละครั้ง เพื่อชำระคืนให้แก่ บริษัท เอ็มพลอยยิ้ม จำกัด
                        </p>
                        <p style="align-self: flex-end; text-align: end;">
                            ลงชื่อ<br>
                            {{ user.fullname  }}

                        </p>
                        <a-col class="accept-box">
                            <div style="display: flex; flex-direction: row;">
                                <input
                                    id="contract-accept"
                                    type="checkbox"
                                    class="checkbox-round"
                                    v-model="accepted"
                                    :onChange="acceptHandler"
                                >
                                <label for="contract-accept"> ข้าพเจ้าอ่านและยอมรับในข้อกำหนดและ เงื่อนไขการให้บริการ</label>
                            </div>
                            <a-button 
                                class="btn-accept" 
                                type="primary" 
                                shape="round" 
                                size="large" 
                                block
                                :loading="loading"
                                :disabled="!accepted"
                                :onClick="onWithdraw">
                                ยอมรับ
                            </a-button>
                        </a-col>
                </div>
        </div>
    </div>
</template>

<script>
import Background from '@/components/Background.vue'

import dayjs from 'dayjs'

import Api from '../../api/Api'
export default {
    name: "Contract",
    props:{
        order_calculated: {
            type: Object,
            default: () => {}
        },
    },
    components: {
            Background
    },
    data() {
        return {
            accepted: false,
            loading: false,
            user: JSON.parse(localStorage.getItem('user'))
        }
    },
    methods: {
        async onWithdraw() {
            this.loading = true
            var res = await Api.create_order(this.order_calculated)
            this.loading = false
            if(res){
                this.$router.replace({name: 'Home'})
            }
        },
        render_date(datetime) {
            return datetime ? dayjs(datetime).format('DD/MM/YYYY') : dayjs().format('DD/MM/YYYY')
        },
        acceptHandler(e) {
            this.accepted = e.target.checked
        }
    },
}
</script>

<style lang="css">
    .content {
        margin-top: 10%;
        position: relative;
        display: flex;
        height: 100%;
        flex-direction: column;
    }
    .accept-box {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 15px;
        width: 100%;
        margin: auto 0 1rem 0;
    }
    .btn-accept {
        background-color: var(--positive-button-color) !important;
        border: none !important;
        height: 50px !important;
    }
    .btn-accept:disabled {
        background-color: var(--gray-color) !important;
    }
    .radio-accept {
        color: var(--text-color) !important;
        font-size: 13px !important;
    }
    .checkbox-round {
        width: 1.5em;
        height: 1.3em;
        background-color: white;
        border-radius: 50%;
        border: 1px solid var(--border-input-color);
        appearance: none;
        cursor: pointer;
        margin: 0.3rem 0.5rem 0 0;
    }

    .checkbox-round:checked {
        background-color: var(--positive-button-color);
        border: 1px solid white;
    }
</style>