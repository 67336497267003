<template>
    <div class="container">
        <Background color="blue"/>
        <div class="scroll-wrapper">
            <div class="withdraw-detail">
                <img src="@/assets/check.png" class="header-marin-top">
                <div class="header-black">ยืนยันคำขอเบิกเงิน</div>
                <WithdrawLimitCard :user="user"/>
                <div class="summary-container">
                        <div class="row-space-between">
                        <div>จำนวนเงินที่ขอเบิก</div>
                        <div>{{ parseFloat(`${withdraw_amount}`).toLocaleString() }} บาท</div>
                        </div>
                        <div class="row-space-between">
                            <div>ค่าธรรมเนียม</div>
                            <div>{{ parseFloat(`${order_calculated.fee}`).toLocaleString() }} บาท</div>
                        </div>
                        <div class="row-space-between">
                            <div>ภาษีมูลค่าเพิ่ม (VAT)</div>
                            <div>{{ parseFloat(`${order_calculated.vat}`).toLocaleString() }} บาท</div>
                        </div>

                        <div style="align-self: start; margin-top: 1rem;">จำนวนเงินที่จะได้รับ</div>
                        <div class="total-withdraw">{{ parseFloat(`${order_calculated.total}`).toLocaleString() }} บาท</div>
                </div>
    
                <a-button 
                        type="primary"  
                        shape="round" 
                        size="large" 
                        block
                        style="margin: auto 0 1rem 0; 
                        background-color: var(--positive-button-color); 
                        border: none;
                        height: 50px;"
                        :onClick="onAccept">
                            ยอมรับ
                </a-button>
            </div>
        </div>
    </div>
</template>

<script>
import Background from '@/components/Background.vue'
import WithdrawLimitCard from './components/WithdrawLimitCard.vue'
import Api from '../../api/Api'
export default {
    name: 'WithdrawConfirm',
    props: {
        withdraw_amount: {
            type: Number,
            default: 0
        },
    },
    components: {
        Background,
        WithdrawLimitCard
    },
    data() {
        return {
            order_calculated: {
                amount: 0,
                fee: 0,
                vat: 0,
                total: 0
            },
            user: JSON.parse(localStorage.getItem('user'))
        }
    },
    methods: {
        onAccept() {
            this.$emitter.emit('order_calculated', this.order_calculated)
            this.$router.push({ name: 'Contract' })
        },
        async cal_ordered(){
            const res = await Api.cal_order({
                amount: this.withdraw_amount
            })
            this.order_calculated = res
        }
    },
    created() {
        this.cal_ordered()
    }
}
</script>

<style lang="css">
.withdraw-detail {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    width: 100%;
    position: relative;
    overflow: auto;
}
.summary-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 85%;
    margin: 0.8rem auto;
    border-radius: 20px;
    padding: 1rem;
    position: relative;
}
.total-withdraw {
    font-size: 20px;
    font-weight: bold;
    margin-top: 0.2rem;
    background-color: white;
    border-radius: 15px;
    border: 2px solid var(--border-input-color);
    padding: 0.5rem 2rem;
    width: 100%;
    align-items: center;
    text-align: center;
}
</style>